const language_en = {
  dashboard: "Dashboard",
  layoutBuilder: "Layout builder",
  craft: "Crafted",
  pages: "Pages",
  profile: "Profile",
  profileOverview: "Overview",
  projects: "Projects",
  campaigns: "Campaigns",
  documents: "Documents",
  connections: "Connections",
  wizards: "Wizards",
  horizontal: "Horizontal",
  vertical: "Vertical",
  account: "Account",
  accountOverview: "Overview",
  settings: "Settings",
  authentication: "Authentication",
  basicFlow: "Basic Flow",
  signIn: "Sign-in",
  signUp: "Sign-up",
  passwordReset: "Password Reset",
  multiStepSignUp: "Multi-steps Sign up",
  error404: "Error 404",
  error500: "Error 500",
  apps: "Apps",
  chat: "Chat",
  privateChat: "Private Chat",
  groupChat: "Group Chat",
  drawerChat: "Drawer Chat",
  widgets: "Widgets",
  widgetsLists: "Lists",
  widgetsStatistics: "Statistics",
  widgetsCharts: "Charts",
  widgetsMixed: "Mixed",
  widgetsTables: "Tables",
  widgetsFeeds: "Feeds",
  changelog: "Changelog",
  docsAndComponents: "Docs & Components",
  megaMenu: "Mega Menu",
  exampleLink: "Example link",
  modals: "Modals",
  general: "General",
  inviteFriends: "Invite Friends",
  viewUsers: "View Users",
  upgradePlan: "Upgrade Plan",
  shareAndEarn: "Share & Earn",
  forms: "Forms",
  newTarget: "New Target",
  newCard: "New Card",
  newAddress: "New Address",
  createAPIKey: "Create API Key",
  twoFactorAuth: "Two Factor Auth",
  createApp: "Create App",
  createAccount: "Create Account",
  documentation: "Documentation",
  components: "Components",
  resources: "Resources",
  activity: "Activity",
  customers: "Customers",
  gettingStarted: "Getting Started",
  customersListing: "Customers Listing",
  customerDetails: "Customers Details",
  calendarApp: "Calendar",
  subscriptions: "Subscriptions",
  getStarted: "Getting Started",
  subscriptionList: "Subscription List",
  addSubscription: "Add Subscription",
  viewSubscription: "View Subscription",

  /* extractSpace */
  login_continue: "Continue",
  invoice_enhancement: "Invoice Enhancement",
  invoice_list: "Invoice list",
  subscription_plan: "Subscription plan",
  status_progress: "in progress",
  status_approval: "need approval",
  status_enhancement: "need enhancements",
  status_completed: "extracted invoices",
  label_subscription_plan: "Subscription plan",
  label_current_plan: "Current plan",
  label_current_bill: "Current monthly/yearly bill",
  label_next_due_date: "Next payment due date",
  label_included_invoices: "Included invoices",
  label_plan_upgrade: "Upgrade plan",
  label_country: "Country",

  /* Account */
  profile_update_success: "The profile has been successfully updated",
  form_label_company_name: "Company / Law Firm",
  form_label_street: "Address line 1",
  form_label_house_number: "Number",
  form_label_extra_address: "Address line 2",
  form_label_city: "City",
  form_label_zip: "Postcode",
  form_label_state: "State / Province",
  form_label_save_button: "Save changes",
  form_label_discard_button: "Discard",
  label_is_admin: "Administrator",
  label_use_billing_address: "Use as a billing address?",
  label_please_wait: "Please wait...",
  label_profile_details: "Profile Details",
  label_profile_address: "Address",
  label_profile_signin: "Sign-in Method",
  label_profile_email: "Email address",
  label_profile_password: "Password",
  form_label_full_name: "Full name",
  form_label_phone: "Contact phone",
  form_label_language: "Language",
  form_label_new_email_address: "Enter New Email Address",
  form_label_confirm_password: "Confirm Password",
  form_label_update_email_address: "Update Email",
  form_label_change_email_address: "Change Email",
  form_label_password_validation_rules: "Password must be at least 8 character and contain symbols",
  form_label_current_password: "Current Password",
  form_label_new_password: "New Password",
  form_label_confirm_new_password: "Confirm New Password",
  form_label_cancel: "Cancel",
  form_label_reset_password: "Reset Password",
  account_settings_label: "Account settings",
  sign_out_label: "Logout",
  edit_profile_btn: "Edit profile",
  profile_details: "Profile details",
  phone_number_hint: "Phone number must be active",

  /* Sign up-in */
  label_language: "Language",
  email_label: "Email",
  password_label: "Password",
  forgot_password_label: "Forgot Password ?",
  or_label: "Or",
  create_account_label: "Create an Account",
  already_account_label: "Already have an account?",
  sign_in_here: "Sign in here",
  first_name: "First Name",
  last_name: "Last Name",
  agree_label: "I Agree &",
  tnc_label: "Terms and conditions",
  password_setup: "Password setup ",
  password_rules: "Use 8 or more characters with a mix of letters, numbers & symbols.",

  /* Invoice statuses */
  upload_invoice: "Upload Invoices",
  ready_for_ai: "Ready for AI",
  in_progress: "In progress",
  action_needed: "Action needed",
  ready_to_submit: "Ready to submit",
  ready_for_ledes: "Ready for LEDES",
  archived: "Archived",
  unarchive: "Unarchive",
  rejected: "Rejected",
  error: "Error",
  adjustment_required: "Adjustments required",

  /* Invoice confirmations */
  confirm_delete: "Are you sure to delete the invoice?",
  confirm_submit: "Do you want to submit the invoice?",
  confirm_ledes: "The LEDES file is available for download. ",
  confirm_archive: "Do you want to archive the invoice?",
  confirm_unarchive: "Do you want to unarchive the invoice?",
  invoice_last_pull: "Last invoice import from AI",
  confirm_button_text: "Yes",
  cancel_button_text: "No, cancel",
  "delete.timekeeper.tooltip": "Delete Timekeeper",
  confirm_delete_tr: "Are you sure to delete the timekeeper record?",
  confirm_delete_fl: "Are you sure to delete the fee line item?",
  confirm_delete_el: "Are you sure to delete the expense line item?",

  /* Invoice listing */
  "invoice.page.title": "Invoices",
  "invoice.table.uploaded_at": "Uploaded at",
  "invoice.table.file_name": "File name",
  "invoice.table.size": "Size",
  "invoice.table.status": "Status",
  "invoice.table.updated_at": "Last modified",
  "invoice.table.actions": "Actions",
  "invoice.table.action.extract": "Convert invoice",
  "invoice.table.action.view": "View invoice",
  "invoice.table.action.delete": "Delete invoice",

  /* Invoice enhancement listing */
  "invoice.enhancement.page.title": "Invoice Enhancement",
  "invoice.enhancement.button.title": "Refresh",
  "invoice.enhancement.table.invoice_no": "Invoice No.",
  "invoice.enhancement.table.invoice_date": "Date",
  "invoice.enhancement.table.invoice_total": "Total",
  "invoice.enhancement.table.currency": "Currency",
  "invoice.enhancement.table.client_name": "Client",
  "invoice.enhancement.table.status": "Status",
  "invoice.enhancement.table.missing_fields": "Number of actions",
  "invoice.enhancement.table.file_size": "File Size",
  "invoice.enhancement.table.updated_at": "Last Modified",
  "invoice.table.action.enhance": "Enhance invoice",
  "invoice.table.action.submit": "Submit invoice",
  "invoice.table.action.archive": "Archive invoice",
  "invoice.table.action.unarchive": "Unarchive invoice",
  "invoice.table.action.ledes": "Download LEDES file",

  /* Invoice Details top section*/
  "invoice.details.page.title": "Invoice Details",
  general_tab: "General",
  timekeeper_tab: "Timekeeper",
  line_items_tab: "Line items",
  close: "Close",
  submit: "Submit",
  "create-ledes": "Create LEDES",
  invoice_number: "Invoice No.",
  invoice_date: "Invoice date",
  invoice_total: "Invoice total",
  invoice_currency: "Currency",
  uploaded_at: "Uploaded at ",
  activity_by: " by ",
  last_modified_at: "Last modified at ",

  /* Invoice details general tab basic info */
  "invoice.details.general.basic_invoice_label": "Basic Invoice data",
  "invoice.details.general.edit_btn": "Edit",
  "invoice.details.general.save_btn": "Save",
  "invoice.details.general.cancel_btn": "Cancel",
  "invoice.details.general.account_type": "Account type",
  "invoice.details.general.account_type.own": "Own",
  "invoice.details.general.account_type.third_party": "3rd party",
  "invoice.details.general.invoice_number": "Invoice number",
  "invoice.details.general.invoice_desc": "Invoice description",
  "invoice.details.general.invoice_date": "Invoice date",
  "invoice.details.general.invoice_total": "Invoice total",
  "invoice.details.general.invoice_net_total": "Invoice net total",
  "invoice.details.general.invoice_currency": "Invoice Currency",
  "invoice.details.general.invoice_tax_total": "Invoice tax total",
  "invoice.details.general.invoice_tax_currency": "Invoice tax currency",
  "invoice.details.general.billing_start_date": "Billing start date",
  "invoice.details.general.billing_end_date": "Billing end date",
  "invoice.details.general.last_updated": "Last updated",

  /* Invoice details general tab law firm info */
  "invoice.details.general.law_firm_label": "Law firm contact details",
  "invoice.details.general.law_firm_id": "Law firm ID",
  "invoice.details.general.law_firm_tax_id": "Law firm tax ID",
  "invoice.details.general.matter_id": "Matter ID",
  "invoice.details.general.matter_name": "Matter name",
  "invoice.details.general.law_firm_name": "Name",
  "invoice.details.general.street1": "Street & number",
  "invoice.details.general.street2": "Street additional",
  "invoice.details.general.city": "City",
  "invoice.details.general.postcode": "Postcode",
  "invoice.details.general.state": "State or Region",
  "invoice.details.general.country": "Country ISO 3 letters",

  /* Invoice details general tab Client contact info */
  "invoice.details.general.client_contact_label": "Client contact details",
  "invoice.details.general.client_id": "Client ID",
  "invoice.details.general.client_matter_id": "Matter ID",
  "invoice.details.general.po_number": "PO number",
  "invoice.details.general.tax_id": "Tax ID",
  "invoice.details.general.client_name": "Name",
  "invoice.details.general.client_street1": "Street & number",
  "invoice.details.general.client_street2": "Street additional",
  "invoice.details.general.client_city": "City",
  "invoice.details.general.client_postcode": "Postcode",
  "invoice.details.general.client_state": "State or Region",
  "invoice.details.general.client_country": "Country ISO 3 letters",

  /* Invoice details timekeeper tab */
  "invoice.details.timekeeper.page.title": "Timekeeper records",
  "invoice.details.timekeeper.timekeeper_id": "Timekeeper ID",
  "invoice.details.timekeeper.timekeeper_name": "Timekeeper name",
  "invoice.details.timekeeper.classification": "Classification",
  "invoice.details.timekeeper.pqe": "PQE",
  "invoice.details.timekeeper.hr_rate": "Hourly rate",

  /* Invoice details line items > fee tab */
  "invoice.details.lineitem.page.title": "Line items",
  "invoice.details.lineitem.fee.tab.title": "Fee items",
  "invoice.details.lineitem.expense.tab.title": "Expenses",
  "invoice.details.lineitem.no": "No.",
  "invoice.details.lineitem.type": "EXP/ FEE/ INV ADJ TYPE",
  "invoice.details.lineitem.date": "Date",
  "invoice.details.lineitem.number_of_units": "Number of hours/units",
  "invoice.details.lineitem.task_code": "Task code",
  "invoice.details.lineitem.activity_code": "Activity code",
  "invoice.details.lineitem.description": "Description",
  "invoice.details.lineitem.hourly_rate": "Hourly rate",
  "invoice.details.lineitem.total_amount": "Total amount",
  "invoice.details.lineitem.tax_rate": "Tax rate",
  "invoice.details.lineitem.tax_type": "Tax type",
  "invoice.details.lineitem.total_tax": "Total tax",
  "invoice.details.lineitem.timekeeper_id": "Timekeeper ID",
  "invoice.details.lineitem.line_amount_total": "Line item total",
  "invoice.details.lineitem.line_amount_tax_total": "Line item tax total",
  "invoice.details.lineitem.expense_code": "Expense code",
  "invoice.details.lineitem.cost_per_unit": "Cost per unit",
  /* Invoice details line items > expenses tab */

  /* Success Error messages */
  success: "Success",
  error: "Error",
  info: "Info",
  "company.update.success": "Company updated successfully!",
  "invoice.upload.success": "Invoice uploaded successfully!",
  "invoice.extraction_request.success": "Successfully requested to convert invoice data!",
  "invoice.delete.success": "Invoice deleted successfully!",
  "auth.login.success": "Logged in successfully!",
  "user.registration.success": "User registered successfully!",
  "passwords.set.success": "Password saved successfully!",
  "user.get.success": "User profile fetched successfully!",
  "user.update.success": "Profile updated successfully!",
  "user.resend_verification.success": "Verification link resent successfully!",
  "passwords.init_reset.success": "Password reset requested successfully!",
  "invoice.update.success": "Invoice updated successfully!",
  "invoice.status.update.success": "Invoice status updated successfully!",
  "invoice.status.update.ready_for_ledes.success": "Invoice submitted successfully!",
  "invoice.status.update.archived.success": "Invoice archived successfully!",
  "user.auth.expired.title": "Please log in again.",
  "user.auth.expired.message": "Your session has expired!",
  "invoice.archive.success": "Invoice archived successfully!",
  "invoice.unarchive.success": "Invoice unarchived successfully!",
  "get.invoice.enhancement.success": "Invoice list refreshed!",
  "datatable.button.new": "New",
  "general.input.placeholder.select": "Select",
  "general.input.placeholder.billing_start": "Billing start date",
  "general.input.placeholder.billing_end": "Billing end date",
  "auth.login.user_not_found": "Login failed. Invalid email ID or password.",
  "auth.login.failed": "Login failed. Invalid email ID or password.",
  "user.email_exists": "User with this email already exists.",
  /* MVP translations ends */
  /* V1 translations begin */
  "online.message": "Your internet connection was restored!",
  "offline.message": "Your internet connection is interrupted. Any ongoing operation may fail.",
  "multi.upload.invoice.line1": "Multiple invoices can be uploaded. Maximum {count} files at one time.",
  "multi.upload.invoice.line2": "(Acceptable file: {formats} )",
  "multi.upload.file.errormsg.successfiles": "{successCount} File(s) passed validation. <br> ",
  "multi.upload.file.errormsg.errorfiles": "{failedCount} File(s) failed validation due to following reasons: <br> ",
  "multi.upload.file.errormsg.filelist": "<br>Affected file(s): <i> {fileList} </i>",
  "multi.upload.file.error.filecount": "You can upload maximum {count} files at one time",
  "multi.upload.file.error.filetype": "File type should be {type}",
  "multi.upload.file.error.filesize": "File size should be less than {size} MB",
  "timekeeper.add.error.timekeeper_id": "Timekeeper ID is required!",
  "timekeeper.add.error.timekeeper_name": "Timekeeper name is required!",
  "timekeeper.add.error.unit_cost": "Hourly rate is required!",
  sso_login_label: "Login with ",
  busylamp_timekeepers_button: "EB.S Timekeepers",
  "timekeeper.not.available.tooltip": "This timekeeper couldn't be found in EB.S",
  "timekeeper.id.not.available": "Only timekeeper with ID can be selected. Please add missing ID in EB.S first to select it.",
  "timekeeper.ebs.list.title": "EB.S Timekeepers (Lawyers/staff)",
  "timekeeper.ebs.list.addAll": "Add all to SIC",
  "timekeeper.ebs.list.addToSIC": "Add to SIC",
  "timekeeper.ebs.list.addedToSIC": "Added to SIC",
  "select.files": "Select files",
  "drag.drop.files": "Drag & drop files to upload",
  "upload.btn.lable": "Upload",
  "valid.file": "Valid",
  "invalid.file": "Invalid",
  "add.new.timekeeper.record": "Add new timekeeper record",
  "add.new.fee.record": "Add new fee item",
  "add.new.expense.record": "Add new expense item",
  "invoice.details.general.invoice_tax_rate": "Fee tax rate",
  "invoice.details.general.expense_tax_rate": "Expense tax rate",
  "password.must.match": "Passwords must match",
  "password.rule.error": "The password does not match the requirements. Please enter at least 8 characters with a mix of letters, numbers and symbols.",
  "accept.timekeeper.tooltip": "Accept the timekeeper",
  confirm_accept_tr: "Are you sure to accept this timekeeper in SIC?",
  "invoice.table.action.export": "Export",
  "invoice.table.action.bulkactions": "Actions",
  "bulk.invoice.archive.success": "{count} Invoices archived.", // need to rearrange the words in key
  "bulk.invoice.status.update.ready_to_submit.success": "{count} Invoices submitted.", // need to rearrange the words in key
  "bulk.invoice.unarchive.success": "{count} Invoices unarchived.",
  "bulk.invoice.delete.success": "{count} Invoices deleted.",
  "bulk.action.status.mismatch": "{action} action not possible for selected invoices.",
  "bulk.invoice.extraction_request.success": "{count} Invoices submitted for conversion",
  "invoice.table.action.adjust": "Unlock for invoice adjustments",
  confirm_adjustement: "Do you want to start with invoice adjustments?",
  "invoice.table.action.adjust_needed": "Adjust invoice",
  "invoice.status.update.adjustment_required.success": "Invoice enabled for adjustments!",
  adjusted_at: "Adjusted at",
  "unlock.for.adjustment.confirmation": "Please unlock the invoice to begin adjustments/modifications",
  "inv.dup.msg": "This invoice is already available in EBilling.Space. Please double check or delete this invoice",
  "number_card.desc.ready_for_ai": "Invoice uploaded which are ready for conversion",
  "number_card.desc.action_needed": "Invoices convierted by AI, need to be enhanced for LEDES",
  "number_card.desc.ready_for_ledes": "Enhancements completed with valid data, LEDES file ready for download",
  "number_card.desc.adjustment_required": "Invoices, that are currently being adjusted",
  "bulk.tk.accept.btn": "Bulk accept timekeepers",
  "dashboard.profile.completion": "Profile completion",
  "dashboard.bar_chart_label": "Invoices processed",
  "dashboard.pie_chart_label": "Invoices status count",
  "dashboard.barchart.yaxis.title": "Invoices",
  "dashboard.barchart.series.a.title": "Uploaded",
  "dashboard.barchart.series.b.title": "Enhanced",
  "dashboard.piechart.series.title": "Invoices",
  "dashboard.piechart.drilldown.series.title": "Other",
  "invoice.details.lineitem.discount_amount": "Discount amount",
  "lineitem.add.error": "{field_name} is required!",
  "invoice.details.lineitem.add.adjustment.title": "Add new discount",
  "invoice.details.lineitem.add.adjustment.description": "Retainer Discount",
  "invoice.upload.send_to_ai_checkbox": "Send to AI conversion after upload",
  "invoice.upload.extraction.success": "Uploaded invoices are successfully submitted for AI conversion!",
  "invoice.adjust.new.file.tooltip": "Alongside submission exchange of invoice file is possible",
  "invoice.upload.adjusted.file": "Upload adjusted/new invoice file",
  "auth.verification_expired": "Link is expired",
  "select.address.notfound.tooltip": "Address not found in eBilling.Space. Please select one from the list",
  "select.address.change.tooltip": "Select lawfirm location from available locations",
  "resend-verification-btn": "Resend verification link",
  "resend-verification-text": "The verification link is expired. Please enter the email address to receive a new link for verification",
  "auth.email_verified": "Email address is already verified.",
  "auth.verification_invalid": "Verification link is invalid",
  "add.bulk.timekeeper.classification": "Add classification",
  "delete.lineitem.tooltip": "Delete line item",
  "add.bulk.activity_code": "Select activity code",
  "add.bulk.timekeeper": "Select timekeeper",
  "add.bulk.tax_rate": "Add tax rate",
  "add.bulk.task_code": "Select task code",
  "error.bulk.modal.timekeeper_id_missing": "ID is missing for the selected Timekeeper. Please add it on the Timekeeper tab to proceed.",
  "add.bulk.expense_code": "Add expense code",
  "invoice.table.action.push.ledes": "Push the LEDES file to EBS",
  "confirm_pushledes": "Are you sure you want to push the generated LEDES file to EBS?",
  "invoice.ledes.transmit.success": "LEDES file successfully pushed to EBS.",
  "invoice.table.action.more": "More actions",
  "invoice.billing_end_date" : "The billing end date must be a date after or equal to the billing start date.",
  "invoice.billing_start_date" : "The date format is incorrect.",
  "timekeeper.not.approved.tooltip": "This timekeeper hourly rate is not approved by client yet",
};

export default language_en;
