const language_de = {
  dashboard: "Dashboard",
  layoutBuilder: "Layout-Builder",
  craft: "Hergestellt",
  pages: "Seiten",
  profile: "Profil",
  profileOverview: "Übersicht",
  projects: "Projekte",
  campaigns: "Kampagnen",
  documents: "Unterlagen",
  connections: "Anschlüsse",
  wizards: "Zauberer",
  horizontal: "Horizontal",
  vertical: "Vertikal",
  account: "Konto",
  accountOverview: "Übersicht",
  settings: "Die Einstellungen",
  authentication: "Authentifizierung",
  basicFlow: "Grundfluss",
  signIn: "Einloggen",
  signUp: "Anmelden",
  passwordReset: "Passwort zurücksetzen",
  multiStepSignUp: "Multi-Steps-Anmeldung",
  error404: "Fehler 404",
  error500: "Fehler 500",
  apps: "Apps",
  chat: "Plaudern",
  privateChat: "Private Chat",
  groupChat: "Privater Chat",
  drawerChat: "Gruppenchat Schubladen-Chat",
  widgets: "Widgets",
  widgetsLists: "Listen",
  widgetsStatistics: "Statistiken",
  widgetsCharts: "Diagramme",
  widgetsMixed: "Gemischt",
  widgetsTables: "Tabellen",
  widgetsFeeds: "Einspeisungen",
  changelog: "Änderungsprotokoll",
  docsAndComponents: "Dokumente & Komponenten",
  megaMenu: "Mega-Menü",
  exampleLink: "Beispiellink",
  modals: "Modale",
  general: "Allgemeines",
  inviteFriends: "Freunde Einladen",
  viewUsers: "Benutzer Anzeigen.",
  upgradePlan: "Upgrade-Plan",
  shareAndEarn: "Teilen & Verdienen",
  forms: "Formen",
  newTarget: "Neues Ziel",
  newCard: "Neue Karte",
  newAddress: "Neue Adresse",
  createAPIKey: "Api-Key Erstellen",
  twoFactorAuth: "Zwei Faktor Auth.",
  createApp: "App Erstellen",
  createAccount: "Benutzerkonto Erstellen",
  activity: "Aktivität",
  documentation: "Dokumentation",
  components: "Bauteile",
  resources: "Ressourcen",
  customers: "Kunden",
  gettingStarted: "Einstieg",
  customersListing: "Kundenauflistung",
  customerDetails: "Kundenangaben",
  calendarApp: "Kalender",
  subscriptions: "Abonnements",
  getStarted: "Einstieg",
  subscriptionList: "Abonnementliste",
  addSubscription: "Subskription Hinzufügen.",
  viewSubscription: "Abonnement Anzeigen.",

  /* extractSpace */
  login_continue: "Weiter",
  invoice_enhancement: "Rechnungsbearbeitung",
  invoice_list: "Rechnungsliste",
  subscription_plan: "Abonnement-Plan",
  status_progress: "in Arbeit",
  status_approval: "Zulassung erforderlich",
  status_enhancement: "Erweiterungsbedarf",
  status_completed: "herausgezogene",
  label_subscription_plan: "Abonnement-Plan",
  label_current_plan: "Aktueller Plan",
  label_current_bill: "Aktuelle monatliche/jährliche Rechnung",
  label_next_due_date: "Nächster Zahlungstermin",
  label_included_invoices: "Beinhaltet Rechungen",
  label_plan_upgrade: "Upgrade plan",
  label_country: "Land",

  /* Account */
  profile_update_success: "Das Profil wurde erfolgreich aktualisiert.",
  form_label_company_name: "Unternehmen",
  form_label_street: "1. Adresse",
  form_label_house_number: "Hausnummer",
  form_label_extra_address: "2. Adresse",
  form_label_city: "Stadt",
  form_label_zip: "PLZ",
  form_label_state: "Staat/Provinz",
  form_label_save_button: "Speichern",
  form_label_discard_button: "Verwerfen",
  label_is_admin: "Administrator",
  label_use_billing_address: "Als Rechnungsadresse verwenden?",
  label_please_wait: "Bitte warte...",
  label_profile_details: "Profildetails",
  label_profile_address: "Adresse",
  label_profile_signin: "Anmeldeverfahren",
  label_profile_email: "Email Adresse",
  label_profile_password: "Passwort",
  form_label_full_name: "Vollständiger Name",
  form_label_phone: "Telefonnummer",
  form_label_language: "Sprache",
  form_label_new_email_address: "Neue Email Adresse eingeben",
  form_label_confirm_password: "Passwort bestätigen",
  form_label_update_email_address: "Email Adresse aktualisieren",
  form_label_change_email_address: "Email Adresse ändern",
  form_label_password_validation_rules: "Das Passwort muss mindestens 8 Zeichen lang sein und Sonderzeichen enthalten.",
  form_label_current_password: "Aktuelles Passwort",
  form_label_new_password: "Neues Passwort",
  form_label_confirm_new_password: "Neues Passwort bestätigen",
  form_label_cancel: "Abbrechen",
  form_label_reset_password: "Passwort zurücksetzen",
  account_settings_label: "Kontoeinstellungen",
  sign_out_label: "Ausloggen",
  edit_profile_btn: "Profil bearbeiten",
  profile_details: "Profildetails",
  phone_number_hint: "Telefonnummer muss aktiv sein",

  /* Sign up-in */
  label_language: "Sprache",
  email_label: "Email",
  password_label: "Paswort",
  forgot_password_label: "Passwort vergessen?",
  or_label: "oder",
  create_account_label: "Neuen Account anlegen",
  already_account_label: "Account bereits vorhanden?",
  sign_in_here: "Hier anmelden",
  first_name: "Vorname",
  last_name: "Nachname",
  agree_label: "Ich bestätige &",
  tnc_label: "Allgemeinen Geschäftsbedingungen",
  password_setup: "Passworteinstellungen",
  password_rules: "Verwenden Sie 8 oder mehr Zeichen mit einer Kombination aus Buchstaben, Zahlen & Sonderzeichen.",

  /* Invoice statuses */
  upload_invoice: "Rechnung hochladen",
  ready_for_ai: "Bereit für AI",
  in_progress: "In Arbeit",
  action_needed: "Bearbeitung erforderlich",
  ready_to_submit: "Bereit für Bestätigung",
  ready_for_ledes: "Bereit für LEDES",
  archived: "Archiviert",
  unarchive: "Archivierung aufheben",
  rejected: "Abgelehnt",
  error: "Fehler",
  adjustment_required: "Anpassungen erforderlich",

  /* Invoice confirmations */
  confirm_delete: "Sind Sie sicher, dass Sie die Rechnung löschen wollen?",
  confirm_submit: "Wollen Sie die Rechnung bestätigen? ",
  confirm_ledes: "Die LEDES Datei steht für den Download bereit.",
  confirm_archive: "Wollen Sie die Rechnung archivieren?",
  confirm_unarchive: "Wollen Sie die Rechnung wieder aktivieren? ",
  invoice_last_pull: "Letzter Import",
  confirm_button_text: "Ja",
  cancel_button_text: "Nein, abbrechen.",
  "delete.timekeeper.tooltip": "Timekeeper löschen",
  confirm_delete_tr: "Sind Sie sicher, dass Sie diesen Datensatz löschen wollen? ",
  confirm_delete_fl: "Sind Sie sicher, dass Sie diesen Datensatz löschen wollen? ",
  confirm_delete_el: "Sind Sie sicher, dass Sie diesen Datensatz löschen wollen? ",

  /* Invoice listing */
  "invoice.page.title": "Rechnungen",
  "invoice.table.uploaded_at": "Hochgeladen am ",
  "invoice.table.file_name": "Dateiname",
  "invoice.table.size": "Größe",
  "invoice.table.status": "Status",
  "invoice.table.updated_at": "Zuletzt geändert",
  "invoice.table.actions": "Aktionen",
  "invoice.table.action.extract": "Rechnung konvertieren",
  "invoice.table.action.view": "Rechnungsansicht",
  "invoice.table.action.delete": "Rechnung löschen",

  /* Invoice enhancement listing */
  "invoice.enhancement.page.title": "Rechnung bearbeiten",
  "invoice.enhancement.button.title": "Aktualisieren",
  "invoice.enhancement.table.invoice_no": "Rechnungsnr.",
  "invoice.enhancement.table.invoice_date": "Datum",
  "invoice.enhancement.table.invoice_total": "Gesamt",
  "invoice.enhancement.table.currency": "Währung",
  "invoice.enhancement.table.client_name": "Mandant",
  "invoice.enhancement.table.status": "Status",
  "invoice.enhancement.table.missing_fields": "Anzahl der Aktionen",
  "invoice.enhancement.table.file_size": "Dateigröße",
  "invoice.enhancement.table.updated_at": "Zuletzt geändert",
  "invoice.table.action.enhance": "Rechnung bearbeiten",
  "invoice.table.action.submit": "Rechnung bestätigen",
  "invoice.table.action.archive": "Rechnung archivieren",
  "invoice.table.action.unarchive": "Archivierung aufheben",
  "invoice.table.action.ledes": "LEDES Datei herunterladen",

  /* Invoice Details top section*/
  "invoice.details.page.title": "Rechnungsdetails",
  general_tab: "Allgemein",
  timekeeper_tab: "Timekeeper",
  line_items_tab: "Rechnungspositionen",
  close: "Schließen",
  submit: "Bestätigen",
  "create-ledes": "LEDES Datei erstellen",
  invoice_number: "Rechnungsnr.",
  invoice_date: "Rechnungsdatum",
  invoice_total: "Rechnungsbetrag brutto",
  invoice_currency: "Währung",
  uploaded_at: "Hochgeladen am  ",
  activity_by: " von ",
  last_modified_at: "Zuletzt geändert am ",

  /* Invoice details general tab basic info */
  "invoice.details.general.basic_invoice_label": "Basisdaten der Rechnung",
  "invoice.details.general.edit_btn": "Ändern",
  "invoice.details.general.save_btn": "Speichern",
  "invoice.details.general.cancel_btn": "Abbrechen",
  "invoice.details.general.account_type": "Kontotyp",
  "invoice.details.general.account_type.own": "Eigenes",
  "invoice.details.general.account_type.third_party": "Drittes",
  "invoice.details.general.invoice_number": "Rechnungsnr.",
  "invoice.details.general.invoice_desc": "Bezeichung der Rechnung",
  "invoice.details.general.invoice_date": "Rechnungsdatum",
  "invoice.details.general.invoice_total": "Rechnungsbetrag brutto",
  "invoice.details.general.invoice_net_total": "Rechnungsbetrag netto ",
  "invoice.details.general.invoice_currency": "Währung der Rechnung",
  "invoice.details.general.invoice_tax_total": "Rechnungssteuersatz",
  "invoice.details.general.invoice_tax_currency": "Steuerwährung",
  "invoice.details.general.billing_start_date": "Rechnungsstartdatum",
  "invoice.details.general.billing_end_date": "Rechnungsendatum",
  "invoice.details.general.last_updated": "Zuletzt hochgeladen",

  /* Invoice details general tab law firm info */
  "invoice.details.general.law_firm_label": "Kontaktdetails der Kanzlei",
  "invoice.details.general.law_firm_id": "Kanzlei ID",
  "invoice.details.general.law_firm_tax_id": "Kanzlei Steuer ID",
  "invoice.details.general.matter_id": "Matter ID",
  "invoice.details.general.matter_name": "Matter Name",
  "invoice.details.general.law_firm_name": "Name",
  "invoice.details.general.street1": "Strasse und Hausnummer",
  "invoice.details.general.street2": "Zusatz Strasse",
  "invoice.details.general.city": "Stadt",
  "invoice.details.general.postcode": "PLZ",
  "invoice.details.general.state": "Bundesland/Region",
  "invoice.details.general.country": "ISO 3 Länderkürzel ",

  /* Invoice details general tab Client contact info */
  "invoice.details.general.client_contact_label": "Kundenkontaktangaben",
  "invoice.details.general.client_id": "Kunden ID ",
  "invoice.details.general.client_matter_id": "Matter ID",
  "invoice.details.general.po_number": "Referenz Nr. ",
  "invoice.details.general.tax_id": "Steuer ID",
  "invoice.details.general.client_name": "Name",
  "invoice.details.general.client_street1": "Strasse & Hausnummer",
  "invoice.details.general.client_street2": "Zusatz Strasse",
  "invoice.details.general.client_city": "Stadt",
  "invoice.details.general.client_postcode": "PLZ",
  "invoice.details.general.client_state": "Bundesland/Region",
  "invoice.details.general.client_country": "ISO 3 Länderkürzel ",

  /* Invoice details timekeeper tab */
  "invoice.details.timekeeper.page.title": "Datensätze des Timekeepers",
  "invoice.details.timekeeper.timekeeper_id": "ID Timekeeper ",
  "invoice.details.timekeeper.timekeeper_name": "Name Timekeeper",
  "invoice.details.timekeeper.classification": "Funktion/Klassifikation",
  "invoice.details.timekeeper.pqe": "PQE",
  "invoice.details.timekeeper.hr_rate": "Stundensatz",

  /* Invoice details line items > fee tab */
  "invoice.details.lineitem.page.title": "Rechnungspositionen",
  "invoice.details.lineitem.fee.tab.title": "Honorarpositionen",
  "invoice.details.lineitem.expense.tab.title": "Ausgaben/Aufwände",
  "invoice.details.lineitem.no": "Nr.",
  "invoice.details.lineitem.type": "EXP/ FEE/ INV ADJ TYP",
  "invoice.details.lineitem.date": "Datum",
  "invoice.details.lineitem.number_of_units": "Anzahl der Stunden/Einheiten",
  "invoice.details.lineitem.task_code": "Aufgabencode",
  "invoice.details.lineitem.activity_code": "Aktivitätscode",
  "invoice.details.lineitem.description": "Beschreibung",
  "invoice.details.lineitem.hourly_rate": "Stundensatz",
  "invoice.details.lineitem.total_amount": "Gesamtbetrag",
  "invoice.details.lineitem.tax_rate": "Steuersatz",
  "invoice.details.lineitem.tax_type": "Steuerart",
  "invoice.details.lineitem.total_tax": "Steuergesamtbetrag",
  "invoice.details.lineitem.timekeeper_id": "ID Timekeeper",
  "invoice.details.lineitem.line_amount_total": "Gesamtbetrag aller Rechnungspositionen ",
  "invoice.details.lineitem.line_amount_tax_total": "Steuergesamtbetrag aller Rechnungspositionen ",
  "invoice.details.lineitem.expense_code": "Code Ausgaben/Aufwände",
  "invoice.details.lineitem.cost_per_unit": "Kosten per Einheit",
  /* Invoice details line items > expenses tab */

  /* Success Error messages */
  success: "Erfolg",
  error: "Fehler",
  info: "Info",
  "company.update.success": "Unternehmensangaben wurden erfolgreich aktualisiert!",
  "invoice.upload.success": "Rechnung wurde erfolgreich hochgeladen!",
  "invoice.extraction_request.success": "Extrahierung von Rechnungsdaten erfolgreich übermittelt!",
  "invoice.delete.success": "Rechnung erfolgreich gelöscht!",
  "auth.login.success": "Anmeldung erfolgreich!",
  "user.registration.success": "Benutzer erfolgreich registriert!",
  "passwords.set.success": "Passwort erfolgreich gespeichert!",
  "user.get.success": "Benutzerprofil erfolgreich ausgewählt!",
  "user.update.success": "Benutzerprofil erfolgreich aktualisiert!",
  "user.resend_verification.success": "Bestätigungslink erneut gesendet!",
  "passwords.init_reset.success": "Das Zurücksetzen des Passwortes war erfolgreich!",
  "invoice.update.success": "Die Rechnung wurde erfolgreich aktualisiert!",
  "invoice.status.update.success": "Der Status der Rechnung wurde erfolgreich aktualisiert!",
  "invoice.status.update.ready_for_ledes.success": "Rechnung erfolgreich bestätigt!",
  "invoice.status.update.archived.success": "Rechnung erfolgreich archiviert!",
  "user.auth.expired.title": "Bitte erneut anmelden.",
  "user.auth.expired.message": "Ihre Sitzung ist abgelaufen!",
  "invoice.archive.success": "Rechnung erfolgreich archiviert!",
  "invoice.unarchive.success": "Die Rechnung wurde erfolgreich aktiviert!",
  "get.invoice.enhancement.success": "Rechnungsliste erfolgreich aktualisiert!",
  "datatable.button.new": "Neu",
  "general.input.placeholder.select": "Bitte wählen",
  "general.input.placeholder.billing_start": "Startdatum der Rechnung",
  "general.input.placeholder.billing_end": "Enddatum der Rechnung",
  "auth.login.user_not_found": "Benutzer nicht gefunden",
  "auth.login.failed": "Benutzer nicht gefunden",
  "user.email_exists": "Ein Benutzer existiert bereits mit dieser E-Mail-Adresse. ",
  /* MVP translations ends */
  /* V1 translations begin */
  "online.message": "Ihre Internetverbindung wurde wiederhergestellt",
  "offline.message": "Ihre Internet Verbindung wurde unterbrochen. Jede weitere Aktion könnte fehlschlagen.",
  "multi.upload.invoice.line1": "Es können bis zu {count} Rechnungen gleichzeitig hochgeladen werden.",
  "multi.upload.invoice.line2": "(Unterstützte Dateiformate: {formats} )",
  "multi.upload.file.errormsg.successfiles": "{successCount} Datei(en) erfolgreich validiert <br> ",
  "multi.upload.file.errormsg.errorfiles": "{failedCount} Datei(en) aufgrund folgender Gründe fehlgeschlagen: <br> ",
  "multi.upload.file.errormsg.filelist": "<br>Betroffene Dateien: <i> {fileList} </i>",
  "multi.upload.file.error.filecount": "Sie können bis zu {count} Dateien auf einmal hochladen. ",
  "multi.upload.file.error.filetype": "Dateit muss {type} sein",
  "multi.upload.file.error.filesize": "Dateigröße muss kleiner als {size} MB sein. ",
  "timekeeper.add.error.timekeeper_id": "ID des Timekeepers wird benötigt",
  "timekeeper.add.error.timekeeper_name": "Name des Timekeepers wird benötigt",
  "timekeeper.add.error.unit_cost": "Stundensatz wird benötigt",
  sso_login_label: "Einloggen mit ",
  busylamp_timekeepers_button: "EB.S Timekeeper",
  "timekeeper.not.available.tooltip": "Dieser Timekeeper konnte in EB.S nicht gefunden werden.",
  "timekeeper.id.not.available": "es können nur Timekeeper mit ID ausgewählt werden. Bitte erfassen sie diese in EB.S",
  "timekeeper.ebs.list.title": "EB.S Timekeepers (Anwälte/Mitarbeiter)",
  "timekeeper.ebs.list.addAll": "Alle hinzufügen",
  "timekeeper.ebs.list.addToSIC": "Hinzufügen",
  "timekeeper.ebs.list.addedToSIC": "Hinzufügen",
  "select.files": "Dateien auswählen",
  "drag.drop.files": "Dateien per drag & Drop hochladen",
  "upload.btn.lable": "Hochladen",
  "valid.file": "Gültig",
  "invalid.file": "Ungültig",
  "add.new.timekeeper.record": "Neuen Timekeeper anlegen",
  "add.new.fee.record": "Neuen Honorareintrag anlegen",
  "add.new.expense.record": "Neuen Auslageneintrag anlegen",
  "invoice.details.general.invoice_tax_rate": "Steuersatz Honorar",
  "invoice.details.general.expense_tax_rate": "Steuersatz Auslagen",
  "password.must.match": "Passwörter müssen übereinstimmen",
  "password.rule.error": "Das Passwort entspricht nicht den Anforderungen. Bitte geben Sie mindestens 8 Zeichen bestehend aus Buchstaben, Zahlen und Sonderzeichen ein.",
  "accept.timekeeper.tooltip": "Timekeeper bestätigen",
  confirm_accept_tr: "Sind Sie sicher, dass Sie diesen Timekeeper übernehmen wollen?",
  "invoice.table.action.export": "Export",
  "invoice.table.action.bulkactions": "Aktionen",
  "bulk.invoice.archive.success": "{count} Rechnungen archiviert.",
  "bulk.invoice.status.update.ready_to_submit.success": "{count} Rechnungen bestätigt.",
  "bulk.invoice.unarchive.success": "{count} Rechnungen Archivierung aufgehoben.",
  "bulk.invoice.delete.success": "{count} Rechnungen gelöscht.",
  "bulk.action.status.mismatch": "{action} Aktionen für die ausgewählten Rechnungen nicht möglich.",
  "bulk.invoice.extraction_request.success": "{count} Rechnungen wurden für die AI Extraktion bestätigt",
  "invoice.table.action.adjust": "Hier klicken, um die Rechnungsanpassung zu starten",
  confirm_adjustement: "Wollen Sie mit der Rechnungsanpassung starten?",
  "invoice.table.action.adjust_needed": "Rechnung anpassen",
  "invoice.status.update.adjustment_required.success": "Rechnung für die Anpassung bereit!!",
  adjusted_at: "Angepasst am",
  "unlock.for.adjustment.confirmation": "Bitte entsperren Sie die Rechnung, bevor Sie mit den Anpassungen beginnen können.",
  "inv.dup.msg": "Diese Rechnung ist bereits in EBilling.Space vorhanden. Bitte prüfen Sie oder löschen Sie diese.",
  "number_card.desc.ready_for_ai": "Hochgeladene Rechnungen, die bereit für die AI Konvertierung sind",
  "number_card.desc.action_needed": "AI konvertierte Rechnungen, die manuelle Anpassungen vor Erstellung der LEDES benötigen",
  "number_card.desc.ready_for_ledes": "Rechnungen, die erfolgreich angepasst wurden, so dass die LEDES Datei heruntergeladen werden kann",
  "number_card.desc.adjustment_required": "LEDES-Dateien/Rechnungen, die sich derzeit in Anpassung befinden",
  "bulk.tk.accept.btn": "Mehrfachauswahl für TK-Bestätigung",
  "dashboard.profile.completion": "Profl vervollständigen",
  "dashboard.bar_chart_label": "Verarbeitete Rechnungen",
  "dashboard.pie_chart_label": "Zählung der Rechnungsstatus",
  "dashboard.barchart.yaxis.title": "Rechnungen",
  "dashboard.barchart.series.a.title": "Hochgeladen",
  "dashboard.barchart.series.b.title": "Angepasste",
  "dashboard.piechart.series.title": "Rechnungen",
  "dashboard.piechart.drilldown.series.title": "Other", // need translation
  "invoice.details.lineitem.discount_amount": "Discount Betrag",
  "lineitem.add.error": "{field_name} erforderlich",
  "invoice.details.lineitem.add.adjustment.title": "Fügen Sie einen Rabatt hinzu",
  "invoice.details.lineitem.add.adjustment.description": "Rabatt",
  "invoice.upload.send_to_ai_checkbox": "AI Konvertierung direkt nach dem Upload starten",
  "invoice.upload.extraction.success": "Hochgeladene Rechnungen wurden erfolgreich an die AI Konvertierung übergeben!",
  "invoice.adjust.new.file.tooltip": "Während der Bestätigung ist der Austausch der Rechnungsdatei möglich",
  "invoice.upload.adjusted.file": "Angepasste/Neue Rechnung hochladen",
  "auth.verification_expired": "Link ist abgelaufen",
  "select.address.notfound.tooltip": "Die Kanzleiadresse wurde nicht gefunden. Bitte wählen Sie eine andere aus. ",
  "select.address.change.tooltip": "Bitte wählen Sie einen Kanzleistandort aus der Liste der verfügbaren Standorte aus. ",
  "resend-verification-btn": "Link zur Verifizierung erneut senden",
  "resend-verification-text": "Der Link für die Verifizierung ist abgelaufen. Bitte tragen Sie Ihre E-Mail Adresse ein, um einen neuen Link zu erhalten",
  "auth.email_verified": "E-Mail Adresse wurde bereits verifiziert",
  "auth.verification_invalid": "Der Link zur Verifizierung ist ungültig",
  "add.bulk.timekeeper.classification": "Füge Klassifikation hinzu",
  "delete.lineitem.tooltip": "Lösche Rechnungsposition",
  "add.bulk.activity_code": "Wähle Aktivitätscode ",
  "add.bulk.timekeeper": "Wähle Timekeeper",
  "add.bulk.tax_rate": "Füge Steuersatz hinzu",
  "add.bulk.task_code": "Wähle Aufgabencode",
  "error.bulk.modal.timekeeper_id_missing": "Die ID für den ausgewählten Timekeeper fehlt. Bitte fügen Sie diesen auf dem Timekeeper-Tab hinzu, um fortzufahren",
  "add.bulk.expense_code": "Füge Ausgabencode hinzu.",
  "invoice.table.action.push.ledes": "LEDES an EB.S übergeben",
  "confirm_pushledes": "Sind Sie sicher, dass Sie die LEDES Datei and EB.S übergeben wollen?",
  "invoice.ledes.transmit.success": "LEDES Datei erfolgreich an EB.S übergeben.",
  "invoice.table.action.more": "Weitere Aktionen",
  "invoice.billing_end_date" : "Das Rechnungsendedatum muss ein Datum nach oder gleich dem Startdatum sein.",
  "invoice.billing_start_date" : "The date format is incorrect.",
  "timekeeper.not.approved.tooltip": "This timekeeper hourly rate is not approved by client yet",
};

export default language_de;
