import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
// import { piniaUserStore } from '@/store/user';
// import { Actions } from '@/store/enums/StoreEnums';
// import JwtService from '@/core/services/JwtService';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/oauth-callback/:provider',
        name: 'oauth-callback',
        component: () =>
          import('@/views/crafted/authentication/sso/OauthCallback.vue'),
      },
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignUp.vue'),
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/PasswordReset.vue'),
      },
      {
        path: '/password',
        name: 'set-password',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SetPassword.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layout/Layout.vue'),
    children: [

      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/invoices/:statusFilter?',
        name: 'invoices',
        component: () => import('@/views/Invoices.vue'),
      },
      {
        path: '/invoice_enhancement/:statusFilter?',
        name: 'enhancement-invoices',
        component: () => import('@/views/Enhancements.vue'),
      },
      {
        path: '/invoice_enhancement/:uuid/details',
        name: 'invoice-details',
        component: () => import('@/views/crafted/invoice-details/InvoiceDetails.vue'),
        children: [
          {
            path: '/invoice_enhancement/:uuid/details/general',
            name: 'general',
            component: () => import('@/views/crafted/invoice-details/General.vue'),
          },
          {
            path: '/invoice_enhancement/:uuid/details/timekeepers',
            name: 'timekeepers',
            component: () => import('@/views/crafted/invoice-details/TimekeeperRecords.vue'),
          },
          {
            path: '/invoice_enhancement/:uuid/details/line-items',
            name: 'line-items',
            component: () => import('@/views/crafted/invoice-details/LineItems.vue'),
          },
        ],
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/crafted/account/Account.vue'),
        children: [
          {
            path: 'overview',
            name: 'account-overview',
            component: () => import('@/views/crafted/account/Overview.vue'),
          },
          {
            path: 'settings',
            name: 'account-settings',
            component: () => import('@/views/crafted/account/Settings.vue'),
          },
          {
            path: 'billing',
            name: 'account-billing',
            component: () => import('@/views/crafted/account/Billing.vue'),
          },
        ],
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/crafted/authentication/Error404.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/crafted/authentication/Error500.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  /* below code used the vue hooks for auth guards
      But the store.isUserAuthenticated always returns true.
      Commenting the code for now. Auth check and redirection is handled in
      Layout and Auth components for now.
      add params (to, from, next) t0 the above function*/

  // var authRoutes = (routes[0] ? routes[0].children?.map(a => a.name) : []);
  // var nonAuthRoutes = routes[1].children?.map(a => a.name);
  // authRoutes?.push('/' as RouteRecordName);
  // nonAuthRoutes?.push('/' as RouteRecordName);

  // const store = piniaUserStore();
  // console.log(store.isUserAuthenticated);

  // if (store.isUserAuthenticated && (nonAuthRoutes?.includes(to.name as string) || to.name === undefined)) {
  //   next({
  //     path: 'invoices',
  //     replace: true,
  //   });
  // } else if (!store.isUserAuthenticated && (authRoutes?.includes(to.name as string) || to.name === undefined)) {
  //   next({
  //     path: 'sign-in',
  //     replace: true,
  //   });
  // } else {
  //   next();
  // }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
