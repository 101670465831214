const ID_TOKEN_KEY = 'id_token' as string;
const ID_SESSION_TOKEN = 'session_token' as string;

const VITE_JWT_SECRET = "Dwk!Pq#HFUf7f28Z7XBuvPfgnhs$2CA#e26P^bq%Hz^&mR!6#^Vnk&Eqp$gvE4Tm";
const VITE_JWT_IV = "23413b358f63432da576c6239035ecbb";
const VITE_JWT_ISSUER = "https://exs.busylamp.com";
const VITE_JWT_AUDIENCE = "9a676beb-4755-4bca-8966-77910b6df23d";

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => window.localStorage.getItem(ID_TOKEN_KEY);

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const saveSessionToken = (token: string): void => {
  window.localStorage.setItem(ID_SESSION_TOKEN, token);
};

export const destroySessionToken = (): void => {
  window.localStorage.removeItem(ID_SESSION_TOKEN);
};

export const saveUserId = (id: string): void => {
  window.localStorage.setItem('UUUI', id);
};

export const destroyUserId = (): void => {
  window.localStorage.removeItem('UUUI');
};

export const getSessionToken = (): string | null => window.localStorage.getItem(ID_SESSION_TOKEN);

export const getPassphrase = (): string => VITE_JWT_SECRET;
export const getEncryptionIv = (): string => VITE_JWT_IV;
export const getIssuer = (): string => VITE_JWT_ISSUER;
export const getAudience = (): string => VITE_JWT_AUDIENCE;

export default {
  getToken,
  saveToken,
  destroyToken,
  getSessionToken,
  saveSessionToken,
  destroySessionToken,
  saveUserId,
  destroyUserId,
  getPassphrase,
  getEncryptionIv,
  getIssuer,
  getAudience,
};
