import { App } from 'vue';
import axios, { AxiosError } from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/JwtService';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import router from '@/router';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {

    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }


  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @param config: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = '' as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    data: unknown,
    params?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, data, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }

  /**
   * @description initialize the interceptor for every request going out
   */
  private static initializeRequestInterceptor = () => {
    ApiService.vueInstance.axios.interceptors.request.use(this.handleRequest);

  };

  /**
   * @description initialize the interceptor for every response coming in
   */
  private static initializeResponseInterceptor = () => {
    ApiService.vueInstance.axios.interceptors.response.use(response => response, this.handleError);
  };

  /**
   * @description set the default HTTP request headers
   */
  private static handleRequest = (config: AxiosRequestConfig) => {
    config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common.Accept = 'application/json';

    return config;
  };

  /**
    * @description handle the response, if "401 Unauthorized" redirect to login
    */
  private static handleError = async (error: AxiosError) => {
    if (error.response?.status === 401) {
      const pre_login_routes: Array<string> = ['/password', '/password-reset', '/sign-up'];
      if (!pre_login_routes.includes(router.currentRoute.value.path)) {
        JwtService.destroyToken();
        JwtService.destroySessionToken();
        // add redirection to login page here
        window.localStorage.setItem("AuthExpired", "true");

        router.push({ name: "sign-in" });
      }
    }

    if (error.response?.status === 500) {
      router.push({ name: "500" });
    }

    return Promise.reject(error);
  };

}

export default ApiService;
